<template>
    <div id="modalContainer" class="modal-container">
        <div class="close-box" @click="closeModal">
            <img src="@/assets/exit.png" alt="...">
        </div>
        <div id="modalBox" class="modal-box">
            <MDBContainer class="button-group mt-1 mb-5">
                <MDBRow >
                    <h3 class="title">{{text[setLang][contentIndex].title}}</h3>
                </MDBRow>
                <MDBRow class="mb-3">
                    <img :src="imgSrc[contentIndex].imgSrc" alt="..." class="mt-2">
                </MDBRow>
                <MDBRow v-if="contentIndex === 7" >
                    <MDBCol md="12">
                        <MDBRow class="mb-3 d-flex align-items-center">
                            <div class="sub-title-text">
                                <h5 class="sub-title">{{text[setLang][contentIndex].title}}</h5>
                            </div>
                            <div class="metaverse-btn-box"></div>
                            <div class="vr-btn-box">
                                <img @click="enterSpace(contentIndex)" class="btn-image" src="@/assets/metaverse-btn.png" alt="..">
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow v-else>
                    <MDBCol md="12">
                        <MDBRow v-for="(item, index) in text[setLang][contentIndex].vrButtons" :key="item" class="mb-3 d-flex align-items-center">
                            <div class="sub-title-text">
                                <h5 class="sub-title">{{item.name}}</h5>
                            </div>
                            <div class="metaverse-btn-box">
                                <img v-if="index===0 || contentIndex===1" @click="enterSpace(contentIndex)" class="btn-image" src="@/assets/metaverse-btn.png" alt="..">
                            </div>
                            <div class="vr-btn-box">
                                <img @click="openExternalLink(item.link)" class="btn-image" src="@/assets/vr-btn.png" alt="..">
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <!-- <div class="desc-box"> -->
            <MDBContainer>
                <p class="description">{{text[setLang][contentIndex].description}}</p>
                <p class="address">{{text[setLang][contentIndex].address}}</p>
            <!-- </div> -->
            </MDBContainer>
        </div>
        <div v-if="isOverFlow" class="scroll-down" @click="scrollDown">
            <img src="@/assets/scroll-down.png" alt="...">
        </div>
    </div>
</template>

<script>
import { onMounted, ref, toRefs } from 'vue';
import lang from '@/lang/language';
import data from '@/common/data';
import { MDBCol, MDBRow, MDBContainer } from "mdb-vue-ui-kit";
import { useGtag } from "vue-gtag-next";

export default {
    props: ["index"],
    components: {
        MDBCol,
        MDBRow,
        MDBContainer
    },
    setup(props) {
        const {index} = toRefs(props);
        const isOverFlow = ref(false);
        const { event } = useGtag();

        onMounted(() => {
            const container = document.getElementById ("modalBox");
            if(container.scrollHeight > self.innerHeight) {
                isOverFlow.value = true;
            }
        });

        return {
            contentIndex: index,
            text: lang.mainModal,
            imgSrc: data,
            isOverFlow,
            event
        }
    },
    computed: {
        setLang() {
            return this.$store.state.user.lang;
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeFromAlertChild");
            this.$emit("zoomOutCamera");
        },
        openExternalLink(link) {
            window.open(link);

            this.event(`enter_ex_${this.contentIndex}`, {
                'event_category' : 'click',
                'event_label' : 'click'
            });
        },
        enterSpace(index) {
            this.event(`enter_space_${this.contentIndex}`, {
                'event_category' : 'click',
                'event_label' : 'click'
            });

            this.$router.push(`/world/lobby?space=${index}`);
        },
        scrollDown() {
            const container = document.getElementById ("modalBox");
            container.scrollBy(0, 50);
        }
    },
}
</script>

<style scoped>
.modal-container {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 35%;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgb(0,0,0,0.2);
    border-radius: 10px;
    z-index: 5;
    overflow: hidden;
    touch-action:none;
}
.modal-box {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: left;
    color: white;
    overflow-y: auto;
    height: 100%;
}
.address {
    font-size: smaller;
}
.title {
    font-weight: bold;
}
.modal-box > img {
    width: 100%;
}
.close-box {
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
}
.close-box > img {
    width: 25px;
    height: 25px;
}
.sub-title {
    margin-bottom: 0px;
    font-weight: bold;
}
.space-btn {
    width: 100%;
    background-color: #ed4343;
    border-radius: 30px;
    padding: 0.8rem 0rem 0.8rem 0rem;
    font-size: normal;
    font-weight: bolder;
    cursor: pointer;
    text-align: center;
}
.space-btn > span {
    line-height: 25px;
}
.metaport-btn {
    width: 100%;
    background-color: white;
    color: #ed4343;
    border-radius: 30px;
    padding: 0.8rem 0rem 0.8rem 0rem;
    font-size: normal;
    font-weight: 900;
    cursor: pointer;
    text-align: center;
}
.metaport-btn  > span {
    line-height: 25px;
}
.btn-image {
    width: 80%;
    max-height: 40px;
    cursor: pointer;
}
.sub-title-text {
    width: 50%;
}
.metaverse-btn-box, .vr-btn-box {
    width: 25%;
    text-align: end;
}
.metaverse-btn-box {
    padding-right: 0px;
}
.vr-btn-box {
    padding-left: 0px;
}
.button-group {
    /* overflow-y: auto; */
    min-height: 65%;
}
.scroll-down {
    position: absolute;
    bottom: 0px;
    background-image: linear-gradient(to bottom, rgba(19, 19, 19, 0), #333);
    width: 100%;
    padding: 0px;
}
.scroll-down > img {
    width: 40px;
}
@media (min-width: 1281px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .modal-container {
        width: 50%;
        z-index: 1000;
    }
    .modal-box {
        padding-top: 2rem;
    }
    .sub-title {
        font-size: small;
    }
    .description {
        font-size: smaller;
    }
    .address {
        font-size: x-small;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .modal-container {
        width: 50%;
        z-index: 1000;
    }
    .modal-box {
        padding-top: 2rem;
    }
    .sub-title {
        font-size: small;
    }
    .description {
        font-size: smaller;
    }
    .address {
        font-size: x-small;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .modal-container {
        width: 100%;
        z-index: 1000;
    }
    .modal-box {
        padding-top: 2rem;
    }
    .sub-title {
        font-size: small;
    }
    .description {
        font-size: smaller;
    }
    .address {
        font-size: x-small;
    }
}

@media (max-width: 481px) {
    .modal-container {
        width: 100%;
        z-index: 1000;
    }
    .modal-box {
        padding-top: 2rem;
    }
    .sub-title {
        font-size: small;
    }
    .description {
        font-size: smaller;
    }
    .address {
        font-size: x-small;
    }
}
</style>