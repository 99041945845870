const data = {
    // 백조일손지지, 섯알오름
    1: {
        imgSrc: "/img/seodal.jpg"
    },
    // 곤을동 잃어버린 마을
    2: {
        imgSrc: "/img/gon.jpg"
    },
    // 주정공장 옛터
    3: {
        imgSrc: "/img/jujeong.jpg"
    },
    // 북촌 너븐숭이
    4: {
        imgSrc: "/img/noven.jpg"
    },
    // 낙선동 43성
    5: {
        imgSrc: "/img/naksun.jpeg"
    },
    // 다랑쉬굴
    6: {
        imgSrc: "/img/darang.jpg"
    },
    7: {
        imgSrc: "/img/memorial.jpeg"
    }
}

export default data;